import React from "react"
import SEO from "../components/seo"
import Logo from '../images/logo-black.svg'
import styles from './404.module.css'

const NotFoundPage = () => (
  <div className={styles.notFound}>
    <SEO title="404: Not found" />
    <div className={styles.message}>
        <Logo className={styles.logo}/>
        <div className={styles.title}>Are you sure you have the right URL?</div>
        <a className={styles.button} href='/'>Back to technology radar</a>
        <div className= {styles.copyright}>
            ©2020 <a href='https://manas.tech'>Manas.Tech</a>
        </div>
    </div>
  </div>
)

export default NotFoundPage
